<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article>
      <asom-card>
        <form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
          <asom-form-field
            :label="'First Aid Box Name'"
            :state="inputStates('formData.boxName')"
            error="First Aid Box Name is required"
            required
          >
            <asom-input-text
              v-model="formData.boxName"
              :state="inputStates('formData.boxName')"
            />
          </asom-form-field>
          <asom-form-field
            :label="'Primary Location'"
            required
            :state="inputStates('formData.primaryLocation')"
            error="Primary Location is required"
          >
            <asom-input-select
              :state="inputStates('formData.primaryLocation')"
              v-model="formData.primaryLocation"
              :loading="isLoadingPrimaryLocations"
              :options="primaryLocationOptions"
              @search-change="queryPrimaryLocations"
              placeholder="Type to search for Primary Locations"
            />
          </asom-form-field>
          <asom-form-field
            :label="'Secondary Location'"
            :state="inputStates('formData.secondaryLocation')"
            error="Secondary Location is required"
            required
          >
            <asom-input-text
              type="text"
              v-model="formData.secondaryLocation"
              :state="inputStates('formData.secondaryLocation')"
            />
          </asom-form-field>
          <div class="col-span-2 flex justify-end space-x-4 pt-8">
            <asom-button
              text="Back"
              variant="secondary"
              @click="$router.go(-1)"
            />
            <asom-button
              text="Create"
              @click="createItemClicked"
              :loading="isSubmitting"
            />
          </div>
        </form>
      </asom-card>
    </article>
  </div>
</template>

<script>
import get from "lodash.get";
import { required } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import {
  createFirstAidBox,
  getListOfInventoryLocations,
} from "../../../../services/inventory.service";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  data() {
    return {
      error: null,
      isSubmitting: null,
      primaryLocationOptions: [],
      isLoadingPrimaryLocations: false,
      formData: {
        boxName: null,
        primaryLocation: null,
        secondaryLocation: null,
      },
    };
  },
  validations() {
    return {
      formData: {
        boxName: { required },
        primaryLocation: { required },
        secondaryLocation: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      lineId: "auth/userLineId",
    }),
  },
  mounted() {
    this.queryPrimaryLocations("", true);
  },
  methods: {
    async queryPrimaryLocations(query, allowEmpty) {
      this.error = null;
      this.isLoadingPrimaryLocations = true;
      if (!query && !allowEmpty) {
        this.isLoadingPrimaryLocations = false;
        this.primaryLocationOptions = [];
      }
      const result = await getListOfInventoryLocations({
        stationId: this.stationId,
        search: query.trim(),
      });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.isLoadingPrimaryLocations = false;
        this.error = null;
        this.primaryLocationOptions = get(result, "payload.list", []).map(
          ({ inventoryLocationId, locationName }) => ({
            label: locationName,
            value: inventoryLocationId,
          })
        );
      } else {
        this.isLoadingPrimaryLocations = false;
        this.error = result.payload;
        this.primaryLocationOptions = [];
      }
    },
    createItemClicked() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.onSubmit();
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      this.isSubmitting = true;
      const result = await createFirstAidBox({
        firstAidBoxName: this.formData.boxName,
        primaryLocationId: get(this.formData.primaryLocation, "value"),
        secondaryLocationName: this.formData.secondaryLocation,
        stationId: this.stationId,
        lineId: this.lineId,
      });
      if (result.success) {
        this.isSubmitting = false;
        this.$router.go(-1);
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
  },
};
</script>
